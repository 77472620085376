<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-card class="mb-4" rounded="lg">
        <v-card-text>
          <managed-residences-select v-model="selectedResidence" @input="selectedResidenceYear = null" />
        </v-card-text>
        <div v-if="selectedResidence">
          <div v-if="!selectedResidenceYear">
            <v-divider />
            <v-card-text>
              <v-select
                item-text="year.name"
                v-model="selectedResidenceYear"
                :loading="$apollo.queries.residenceYears.loading"
                :items="residenceYears"
                :label="$t('residenceYear.name')"
                return-object />
            </v-card-text>
          </div>
        </div>
      </v-card>

      <edit-card
        v-if="selectedResidenceYear && !$apollo.queries.residenceYear.loading"
        model="residenceYear"
        :info="info"
        :item="residenceYear"
        :title="$tc('models.residence_year', 1) + ` ${selectedResidenceYear.year.name}`">
        <template #title>
          <v-select
            item-text="year.name"
            v-model="selectedResidenceYear"
            :loading="$apollo.queries.residenceYears.loading"
            :items="residenceYears"
            :label="$t('residenceYear.name')"
            return-object />
        </template>
        <template v-slot:form="{ close }">
          <residence-year-form :residence_year_id="selectedResidenceYear.id" @cancel="close" @save="close" />
        </template>
      </edit-card>
      <v-skeleton-loader type="card" v-if="$apollo.queries.residenceYear.loading" />
    </v-col>
    <v-col cols="12" md="8" v-if="selectedResidenceYear">
      <v-card class="overflow-hidden" rounded="lg" v-if="!$apollo.queries.residenceYear.loading">
        <tuition-ranges-table
          @tuitionRangeCreated="tuitionRangeCreated"
          @tuitionRangeDeleted="tuitionRangeDeleted"
          :tuitionRanges="residenceYear.tuitionRanges"
          :baseItem="{ residenceYear: selectedResidenceYear }" />
      </v-card>
      <v-skeleton-loader v-else type="table" />
    </v-col>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import EditCard from "../base/EditCard.vue";
import ManagedResidencesSelect from "../inputs/ManagedResidencesSelect.vue";
import TuitionRangesTable, { TUITION_RANGES_TABLE_FRAGMENT } from "../tables/TuitionRangesTable.vue";
import { addToCacheArray, removeFromCacheArray } from "../../apollo/helpers";
import ResidenceYearForm from "../forms/ResidenceYearForm.vue";

const RESIDENCE_YEAR_TUITION_RANGES = gql`
  query ResidenceYearTuitionRanges($id: ID!) {
    residenceYear(id: $id) {
      id
      canUpdate
      max_tuition
      education_bonus
      tuitionRanges {
        ...tuitionRangesTable
      }
    }
  }
  ${TUITION_RANGES_TABLE_FRAGMENT}
`;

export default {
  components: { EditCard, ManagedResidencesSelect, TuitionRangesTable, ResidenceYearForm },
  name: "TuitionRangesSection",
  data() {
    return {
      residenceYears: [],
      selectedResidence: null,
      selectedResidenceYear: null,
      info: [
        {
          field: "max_tuition",
          type: "currency",
          cols: 12,
        },
        {
          field: "education_bonus",
          type: "currency",
          cols: 12,
        },
      ],
    };
  },
  methods: {
    tuitionRangeCreated(newElement) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: RESIDENCE_YEAR_TUITION_RANGES,
          variables: {
            id: this.selectedResidenceYear.id,
          },
        },
        queryField: "residenceYear.tuitionRanges",
        newElement,
      });
    },

    tuitionRangeDeleted({ id }) {
      removeFromCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: RESIDENCE_YEAR_TUITION_RANGES,
          variables: {
            id: this.selectedResidenceYear.id,
          },
        },
        queryField: "residenceYear.tuitionRanges",
        id,
      });
    },
  },
  apollo: {
    residenceYears: {
      query: gql`
        query ResidenceYears($id: ID!) {
          residence(id: $id) {
            id
            residenceYears {
              id
              year {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.selectedResidence.id,
        };
      },
      update: (data) => data.residence.residenceYears,
      skip() {
        return this.selectedResidence == null;
      },
    },
    residenceYear: {
      query: RESIDENCE_YEAR_TUITION_RANGES,
      variables() {
        return {
          id: this.selectedResidenceYear.id,
        };
      },
      skip() {
        return this.selectedResidenceYear == null;
      },
    },
  },
};
</script>
