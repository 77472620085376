<template>
  <div>
    <v-card class="overflow-hidden" rounded="lg">
      <v-card-title>
        <client-years-select @input="items = null" v-model="selectedYear" />
      </v-card-title>
    </v-card>
    <br />
    <div v-if="selectedYear">
      <v-card v-for="structure in structures" :key="structure.id" class="overflow-hidden mb-4" rounded="lg">
        <crud-table
          :items="structure.cfuReferences"
          model="cfu_reference"
          :title="$tc('cfuReference.name', 2) + ' - ' + structure.name"
          :loading="loading"
          @deleteItem="deleteCfuReference"
        >
          <template v-slot:form="{ selected_id, close, duplicating }">
            <cfu-reference-form
              :baseItem="{
                cfu_reference: 1,
                year: selectedYear,
                structure,
              }"
              :cfu_reference_id="selected_id"
              :duplicating="duplicating"
              @cancel="close"
              @save="close"
              @created="(newElement) => cfuReferenceCreated(newElement, close)"
            />
          </template>
        </crud-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import CrudTable from "../base/CrudTable.vue";
import CfuReferenceForm, { CFU_REFERENCE_FRAGMENT } from "../forms/CfuReferenceForm.vue";
import ClientYearsSelect from "../inputs/ClientYearsSelect.vue";
import { addToCacheArray, removeFromCacheArray } from "../../apollo/helpers";
import { deleteMutationBuilder } from "../../graphql/mutations";

const CFU_REFERENCES = gql`
  query YearCfuReferences($year_id: ID!) {
    year(id: $year_id) {
      id
      name
      structures {
        id
        name
        cfuReferences(year_id: $year_id) {
          id
          cfu_reference
          classYear {
            id
            name
          }
          structure {
            id
          }
        }
      }
    }
  }
`;

export default {
  components: { CrudTable, CfuReferenceForm, ClientYearsSelect },
  name: "CfuReferencesSection",
  data() {
    return {
      selectedYear: null,
      structures: [],
    };
  },
  apollo: {
    structures: {
      query: CFU_REFERENCES,
      variables() {
        return { year_id: this.selectedYear.id };
      },
      update: (data) => data.year.structures,
      skip() {
        return !this.selectedYear;
      },
    },
  },
  computed: {
    loading() {
      return this.$apollo.queries.structures.loading;
    },
  },
  methods: {
    async cfuReferenceCreated(newElement, close) {
      let structure_index = this.structures.findIndex((s) => s.id === newElement.structure.id);

      if (structure_index === -1) {
        await this.$apollo.queries.structures.refetch();
        structure_index = this.structures.findIndex((s) => s.id === newElement.structure.id);
      }
      
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: CFU_REFERENCES,
          variables: { year_id: this.selectedYear.id },
        },
        queryField: "year.structures[" + structure_index + "].cfuReferences",
        newElement,
      });
      close();
    },

    deleteCfuReference({ id, structure }) {
      const structure_index = this.structures.findIndex((s) => s.id === structure.id);
      this.$apollo.mutate({
        mutation: deleteMutationBuilder({
          model: "CfuReference",
          fragment: CFU_REFERENCE_FRAGMENT,
          fragment_name: "cfuReference",
        }),
        variables: { id },
        update: (store, { data: { deleteCfuReference } }) =>
          removeFromCacheArray(store, {
            query: {
              query: CFU_REFERENCES,
              variables: { year_id: this.selectedYear.id },
            },
            queryField: "year.structures[" + structure_index + "].cfuReferences",
            id: deleteCfuReference.id,
          }),
      });
    },
  },
};
</script>

<style></style>
