<template>
  <base-form
    model="cfuReference"
    @save="cfu_reference_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.cfuReference.loading"
    :currentValue="cfuReference"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import { ucFirst } from "../../apollo/helpers";

export const CFU_REFERENCE_FRAGMENT = gql`
  fragment cfuReference on CfuReference {
    id
    cfu_reference
    classYear {
      id
      name
    }
    structure {
      id
      name
    }
  }
`;

export default {
  components: { BaseForm },
  name: "CfuReferenceForm",
  props: {
    cfu_reference_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    cfu_reference_id(val) {
      if (val == null) this.cfuReference = {};
    },
  },

  apollo: {
    cfuReference: {
      query: gql`
        query CfuReference($id: ID!) {
          cfuReference(id: $id) {
            ...cfuReference
          }
        }
        ${CFU_REFERENCE_FRAGMENT}
      `,
      variables() {
        return { id: this.cfu_reference_id };
      },
      skip() {
        return this.cfu_reference_id == null;
      },
    },
  },

  data() {
    return {
      newValue: {},
      cfuReference: {},
      saving: false,
      fields: [
        {
          name: "classYear",
          path: "classYears",
          label: ucFirst(this.$t("classYear.name")),
          type: "query",
          query: gql`
            query ClassYears {
              classYears {
                id
                name
              }
            }
          `,
          validation: {
            required,
          },
        },
        {
          name: "cfu_reference",
          type: "number",
          bind: {
            step: 1,
            min: 1,
          },
          validation: {
            required,
          },
        },
        {
          name: "structure",
          path: "year.structures",
          label: ucFirst(this.$t("structure.name")),
          type: "query",
          query: gql`
            query Structures {
              year(id: $year_id) {
                structures {
                  id
                  name
                }
              }
            }
          `,
          variables: () => {
            return { year_id: this.baseItem.year.id };
          },
          validation: {
            required,
          },
        },
      ],
    };
  },

  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "CfuReference",
            fragment: CFU_REFERENCE_FRAGMENT,
            fragment_name: "cfuReference",
          }),
          variables: {
            id: this.cfu_reference_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "CfuReference",
            fragment: CFU_REFERENCE_FRAGMENT,
            fragment_name: "cfuReference",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createCfuReference } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createCfuReference);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
