<template>
  <base-form
    model="chamber"
    @save="chamber_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.chamber.loading"
    :currentValue="chamber"
    :defaultValue="baseItem"
    :saving="saving"
    :duplicating="duplicating"
    @input="(value) => (newValue = value)"
    @cancel="$emit('cancel')"
    ref="form"
  ></base-form>
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import { minValue } from "vuelidate/lib/validators";
import { integer } from "vuelidate/lib/validators";

const CHAMBER_FRAGMENT = gql`
  fragment chamberForm on Chamber {
    id
    name
    beds
    floor {
      id
      name
    }
  }
`;

export default {
  components: { BaseForm },
  name: "ChamberForm",
  props: {
    chamber_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    chamber_id(val) {
      if (val == null) this.chamber = {};
    },
  },

  apollo: {
    chamber: {
      query: gql`
        query Chamber($id: ID!) {
          chamber(id: $id) {
            ...chamberForm
          }
        }
        ${CHAMBER_FRAGMENT}
      `,
      variables() {
        return { id: this.chamber_id };
      },
      skip() {
        return this.chamber_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      chamber: {},
      saving: false,
      fields: [
        {
          name: "name",
          validation: {
            required,
          },
        },
        {
          name: "beds",
          type: "number",
          bind: {
            step: 1,
            min: 1,
          },
          validation: {
            required,
            minValue: minValue(1),
            integer: integer,
          },
        },
        {
          name: "floor",
          type: "query",
          path: "floor.residence.floors",
          query: gql`
            query FloorResidenceFloors($id: ID!) {
              floor(id: $id) {
                id
                residence {
                  id
                  floors {
                    id
                    name
                  }
                }
              }
            }
          `,
          variables: () => {
            return { id: this.baseItem.floor.id };
          },
          label: this.$t("floor.name"),
          validation: {
            required,
          },
        },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Chamber",
            fragment: CHAMBER_FRAGMENT,
            fragment_name: "chamberForm",
          }),
          variables: {
            id: this.chamber_id,
            input: this.newValue,
          },
        })
        .then(({ data: { updateChamber } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("updated", updateChamber);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Chamber",
            fragment: CHAMBER_FRAGMENT,
            fragment_name: "chamberForm",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createChamber } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createChamber);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
