<template>
  <base-form
    model="tuitionRange"
    @save="tuition_range_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.tuitionRange.loading"
    :currentValue="tuitionRange"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>


<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";

export const TUITION_RANGE_FORM_FRAGMENT = gql`
  fragment tuitionRangeForm on TuitionRange {
    id
    name
    isee_limit
    isee_reduction
    residenceYear {
      id
    }
  }
`;

export default {
  components: { BaseForm },
  name: "TuitionRangeForm",
  props: {
    tuition_range_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    tuition_range_id(val) {
      if (val == null) this.tuitionRange = {};
    },
  },

  apollo: {
    tuitionRange: {
      query: gql`
        query TuitionRange($id: ID!) {
          tuitionRange(id: $id) {
            ...tuitionRangeForm
          }
        }
        ${TUITION_RANGE_FORM_FRAGMENT}
      `,
      variables() {
        return { id: this.tuition_range_id };
      },
      skip() {
        return this.tuition_range_id == null;
      },
    },
  },

  data() {
    return {
      newValue: {},
      tuitionRange: {},
      saving: false,
      fields: [
        {
          name: "name",
          validation: {
            required,
          },
        },
        {
          name: "isee_limit",
          type: "number",
          validation: {
            required,
          },
          bind: {
            step: 0.01,
            min: 0,
            "append-icon": "mdi-currency-eur",
          },
        },
        {
          name: "isee_reduction",
          type: "number",
          validation: {
            required,
          },
          bind: {
            step: 0.01,
            min: 0,
            "append-icon": "mdi-currency-eur",
          },
        },
      ],
    };
  },

  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "TuitionRange",
            fragment: TUITION_RANGE_FORM_FRAGMENT,
            fragment_name: "tuitionRangeForm",
          }),
          variables: {
            id: this.tuition_range_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "TuitionRange",
            fragment: TUITION_RANGE_FORM_FRAGMENT,
            fragment_name: "tuitionRangeForm",
          }),
          variables: {
            input: this.newValue,
          },
        })
        .then(({ data: { createTuitionRange } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createTuitionRange);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>