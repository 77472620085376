<template>
  <v-card class="overflow-hidden" rounded="lg">
    <base-table
      v-if="!$apollo.queries.customLinks.loading"
      :items="customLinks"
      :title="$tc('models.customLink', 2)"
      :headers="headers"
      @deleteItem="deleteCustomLink"
      model="customlink"
    >
      <template v-slot:form="{ selected_id, close, duplicating }">
        <custom-link-form
          :custom_link_id="selected_id"
          :baseItem="{ client }"
          :duplicating="duplicating"
          @cancel="close"
          @save="close"
          @created="(newElement) => customlinkCreated(newElement, close)"
        />
      </template>
    </base-table>
    <v-skeleton-loader v-else type="table" />
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import BaseTable from "@/components/tables/BaseTable.vue";
import CustomLinkForm from "../forms/CustomLinkForm.vue";
import { addToCacheArray, deleteModel } from "../../apollo/helpers";

const CUSTOM_LINKS_QUERY = gql`
  query ClientCustomLinks {
    me {
      id
      client {
        id
        customLinks {
          id
          name
          link
        }
      }
    }
  }
`;

export default {
  components: { BaseTable, CustomLinkForm },
  data() {
    return {
      customLinks: [],
      client: null,

      headers: [
        {
          text: this.$t("customLink.name"),
          value: "name",
        },
        {
          text: this.$t("customLink.link"),
          value: "link",
          type: "link",
        },
      ],
    };
  },
  methods: {
    customlinkCreated(newElement, close) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: { query: CUSTOM_LINKS_QUERY },
        queryField: "me.client.customLinks",
        newElement,
      });
      close();
    },

    deleteCustomLink({ id }) {
      deleteModel(this.$apollo, {
        id,
        model: "CustomLink",
        query: { query: CUSTOM_LINKS_QUERY },
        queryField: "me.client.customLinks",
      });
    },
  },

  apollo: {
    customLinks: {
      query: CUSTOM_LINKS_QUERY,
      update(data) {
        this.client = data.me.client;
        return data.me.client.customLinks;
      },
    },
  },
};
</script>
