<template>
  <admin-layout
    :title="$t('navigation.dashboard')"
    :value="section"
    :drawerItems="drawerItems"
    routeName="Administration"
  >
    <template v-slot:tag>
      <v-card class="overflow-hidden" rounded="lg">
        <crud-table
          v-if="!loading"
          :items="tags"
          model="tag"
          :title="$tc('models.tag', 2)"
          :loading="loading"
          @deleteItem="deleteTag"
        >
          <template v-slot:[`color`]="{ item }">
            <v-chip :color="item.color"><span class="px-1"></span></v-chip>
          </template>
          <template v-slot:form="{ selected_id, close, duplicating }">
            <tag-form
              :tag_id="selected_id"
              :duplicating="duplicating"
              @cancel="close"
              @save="close"
              @created="(newElement) => tagCreated(newElement, close)"
            />
          </template>
        </crud-table>
        <v-skeleton-loader type="table" v-else />
      </v-card>
    </template>

    <template v-slot:studentYearTag>
      <v-card class="overflow-hidden" rounded="lg">
        <student-year-tags-table
          :student-years-tags="studentYearTags"
          @studentYearTagCreated="($event) => studentYearTagCreated($event)"
          @studentYearTagDeleted="($event) => studentYearTagDeleted($event)"
        >
        </student-year-tags-table>
        <!-- <v-skeleton-loader type="table" v-else /> -->
      </v-card>
    </template>

    <template v-slot:grade_reference><grade-references-section /></template>

    <template v-slot:cfu_reference><cfu-references-section /></template>

    <template v-slot:study_class>
      <v-card class="overflow-hidden" rounded="lg">
        <crud-table
          v-if="!loading"
          :items="studyClasses"
          model="study_class"
          :title="$tc('models.study_class', 2)"
          :loading="loading"
          @createItem="createStudyClass"
          @deleteItem="deleteStudyClass"
          @updateItem="updateStudyClass"
        >
          <template v-slot:[`color`]="{ item }">
            <v-chip :color="item.color"><span class="px-1" /></v-chip>
          </template>
        </crud-table>
        <v-skeleton-loader type="table" v-else />
      </v-card>
    </template>

    <template v-slot:tuition_range>
      <tuition-ranges-section />
    </template>

    <template v-slot:room>
      <rooms-section />
    </template>

    <template v-slot:client>
      <client-section />
    </template>

    <template v-slot:chamber>
      <chamber-section />
    </template>

    <template v-slot:custom-links>
      <custom-links-section />
    </template>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import { addToCacheArray, createModel, deleteModel, ucFirst, updateModel } from "../apollo/helpers";
import CrudTable from "@/components/base/CrudTable.vue";

import { TAGS } from "../graphql/queries";

import gql from "graphql-tag";
import fragments from "@/graphql/fragments";
import TuitionRangesSection from "../components/administration/TuitionRangesSection.vue";
import GradeReferencesSection from "../components/administration/GradeReferencesSection.vue";
import TagForm from "../components/forms/TagForm.vue";
import RoomsSection from "../components/administration/RoomsSection.vue";
import CfuReferencesSection from "../components/administration/CfuReferencesSection.vue";
import ClientSection from "../components/administration/ClientSection.vue";
import ChamberSection from "../components/administration/ChamberSection.vue";
import CustomLinksSection from "../components/administration/CustomLinksSection.vue";

import StudentYearTagsTable, { STUDENT_YEAR_TAGS_TABLE_FRAGMENT } from "../components/tables/StudentYearTagsTable.vue";
import { removeFromCacheArray } from "../apollo/helpers";

const STUDY_CLASSES = gql`
  query StudyClasses {
    studyClasses {
      ...study_class
    }
  }
  ${fragments.study_class}
`;

const STUDENT_YEAR_TAGS = gql`
  query StudentYearTags {
    me {
      id
      client {
        id
        studentYearTags {
          ...studentYearTagsTable
        }
      }
    }
  }
  ${STUDENT_YEAR_TAGS_TABLE_FRAGMENT}
`;

export default {
  components: {
    CrudTable,
    TuitionRangesSection,
    GradeReferencesSection,
    TagForm,
    RoomsSection,
    CfuReferencesSection,
    StudentYearTagsTable,
    ClientSection,
    AdminLayout,
    ChamberSection,
    CustomLinksSection,
  },
  props: {
    section: {
      type: String,
    },
  },
  data() {
    return {
      tags: [],
      studentYearTags: [],
      studyClasses: [],
      drawerItems: [
        { title: this.$tc("models.tag", 2), icon: "mdi-tag", section: "tag" },
        { title: this.$tc("models.studentYearTag", 2), icon: "mdi-badge-account", section: "studentYearTag" },
        { title: this.$tc("models.grade_reference", 2), icon: "mdi-chart-line", section: "grade_reference" },
        { title: this.$tc("models.cfu_reference", 2), icon: "mdi-chart-bar", section: "cfu_reference" },
        { title: this.$tc("models.study_class", 2), icon: "mdi-google-classroom", section: "study_class" },
        { title: this.$tc("models.tuition_range", 2), icon: "mdi-cash-multiple", section: "tuition_range" },
        { title: this.$tc("models.room", 2), icon: "mdi-map-marker", section: "room" },
        { title: this.$tc("client.documents_layout", 2), icon: "mdi-pencil-ruler", section: "client" },
        { title: this.$tc("models.chamber", 2), icon: "mdi-bunk-bed", section: "chamber" },
        { title: this.$tc("models.customLink", 2), icon: "mdi-link", section: "custom-links" },
      ],
      mini: true,
      drawer: true,
    };
  },
  computed: {
    loading() {
      return this.$apollo.queries.tags.loading;
    },
  },
  methods: {
    ucFirst,

    tagCreated(newElement, close) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: { query: TAGS },
        queryField: "me.client.tags",
        newElement,
        orderBy: { name: "asc" },
      });
      close();
    },

    deleteTag({ id }) {
      deleteModel(this.$apollo, {
        id,
        query: { query: TAGS },
        queryField: "me.client.tags",
        model: "Tag",
      });
    },

    createStudyClass(input) {
      createModel(this.$apollo, {
        input,
        model: "StudyClass",
        query: STUDY_CLASSES,
        queryField: "studyClasses",
      });
    },

    updateStudyClass(item) {
      updateModel(this.$apollo, {
        item,
        model: "StudyClass",
        queryField: "studyClasses",
      });
    },

    deleteStudyClass({ id }) {
      deleteModel(this.$apollo, {
        id,
        query: STUDY_CLASSES,
        model: "StudyClass",
        queryField: "studyClasses",
      });
    },

    studentYearTagCreated(newElement) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: STUDENT_YEAR_TAGS,
        },
        queryField: "me.client.studentYearTags",
        newElement,
      });
    },

    studentYearTagDeleted({ id }) {
      removeFromCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: STUDENT_YEAR_TAGS,
        },
        queryField: "me.client.studentYearTags",
        id,
      });
    },
  },

  apollo: {
    tags: {
      query: TAGS,
      update: (data) => data.me.client.tags,
    },
    studentYearTags: {
      query: STUDENT_YEAR_TAGS,
      update: (data) => data.me.client.studentYearTags,
    },
    studyClasses: {
      query: STUDY_CLASSES,
    },
  },
};
</script>

<style></style>
