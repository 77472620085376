<template>
  <v-card class="overflow-hidden" rounded="lg">
    <base-table
      :items="floor.chambers"
      :headers="headers"
      :title="$tc('models.floor', 1) + ' - ' + floor.name"
      :tableProps="{ itemsPerPage: 15 }"
      @deleteItem="deleteChamber"
    >
      <template v-slot:toolbar>
        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab depressed x-small color="primary" dark v-bind="attrs" v-on="on" class="ml-1">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list dense class="py-0">
            <v-list-item @click="dialog = true"> <v-icon>mdi-pencil</v-icon> Edit </v-list-item>
            <v-list-item @click="dialogDelete = true"> <v-icon>mdi-delete</v-icon> Delete </v-list-item>
          </v-list>
        </v-menu>
        <v-dialog v-model="dialog" max-width="800px" persistent>
          <floor-form @cancel="dialog = false" @save="dialog = false" :floor_id="floor.id"></floor-form>
        </v-dialog>
        <delete-dialog v-model="dialogDelete" @confirm="deleteFloor" />
      </template>
      <template v-slot:[`students`]="{ item }">
        <div class="d-flex flex-column align-start">
          <v-chip
            v-for="studentYear in item.studentYears"
            :key="studentYear.id"
            color="primary"
            small
            class="ma-1 flex"
          >
            {{ studentYear.full_name }}
          </v-chip>
        </div>
      </template>
      <template v-slot:form="{ selected_id, close, duplicating }">
        <chamber-form
          :chamber_id="selected_id"
          :duplicating="duplicating"
          :baseItem="{ floor }"
          @cancel="close"
          @save="close"
          @created="(newElement) => createdChamber(newElement, close)"
          @updated="(updatedElement) => updatedChamber(updatedElement, close)"
        />
      </template>
    </base-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import ChamberForm from "@/components/forms/ChamberForm.vue";
import BaseTable from "../../tables/BaseTable.vue";
import FloorForm from "../../forms/FloorForm.vue";
import DeleteDialog from "../../DeleteDialog.vue";
import { ucFirst } from "@/apollo/helpers";

export const CHAMBERS_TABLE_FRAGMENT = gql`
  fragment chambersTable on Floor {
    id
    name
    chambers {
      id
      name
      beds
      studentYears(year_id: $year_id) {
        id
        full_name
      }
    }
  }
`;

export default {
  components: { ChamberForm, BaseTable, FloorForm, DeleteDialog },
  name: "ChambersTable",
  props: {
    floor: { type: Object, required: true },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("chamber.name"),
          value: "name",
        },
        {
          text: ucFirst(this.$tc("models.student", 2)),
          value: "students",
          type: "template",
        },
        {
          text: this.$t("chamber.beds"),
          value: "beds",
        },
      ],
      dialog: false,
      dialogDelete: false,
    };
  },
  methods: {
    createdChamber(newElement, close) {
      this.$emit("createdChamber", { ...newElement, studentYears: [] });
      close();
    },
    updatedChamber(updatedElement, close) {
      this.$emit("updatedChamber", {
        ...updatedElement,
        studentYears: this.floor.chambers.find((chamber) => chamber.id === updatedElement.id)?.studentYears,
      });
      close();
    },
    deleteChamber(chamber) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteChamber($id: ID!) {
              deleteChamber(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: chamber.id,
          },
        })
        .then(({ data }) => {
          this.$emit("deletedChamber", data.deleteChamber);
        });
    },
    deleteFloor() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteFloor($id: ID!) {
              deleteFloor(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: this.floor.id,
          },
        })
        .then(({ data }) => {
          const floor = data.deleteFloor;
          this.$emit("deletedFloor", floor);
        });
    },
  },
};
</script>
