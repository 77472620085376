<template>
  <base-form
    model="studentYearTag"
    @save="student_year_tag_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.studentYearTag.loading"
    :currentValue="studentYearTag"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required, maxValue, minValue } from "vuelidate/lib/validators";

const STUDENT_YEAR_TAG_FORM_FRAGMENT = gql`
  fragment studentYearTagForm on StudentYearTag {
    id
    name
    has_academic_profile
    has_education_profile
    has_administration_profile
    has_transcript
    goal_time
    years {
      id
      name
    }
    transcript_header_text
    transcript_footer_text
    transcript_show_student_year_tag
    fill_logo_color
    logo: file(name: "logo") {
      id
      name
      link
    }
    transcriptFooterLogo: file(name: "transcriptFooterLogo") {
      id
      name
      link
    }
  }
`;

const STUDENT_YEAR_TAG_MUTATION_FRAGMENT = gql`
  fragment studentYearTagMutation on StudentYearTag {
    ...studentYearTagForm
    transcriptSettings {
      header_text
      footer_text
      show_student_year_tag
      fill_logo_color
    }
  }
  ${STUDENT_YEAR_TAG_FORM_FRAGMENT}
`;

export default {
  components: { BaseForm },
  name: "StudentYearTag",
  props: {
    student_year_tag_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    student_year_tag_id(val) {
      if (val == null) this.studentYearTag = {};
    },
  },

  apollo: {
    studentYearTag: {
      query: gql`
        query StudentYearTag($id: ID!) {
          studentYearTag(id: $id) {
            ...studentYearTagForm
          }
        }
        ${STUDENT_YEAR_TAG_FORM_FRAGMENT}
      `,
      variables() {
        return { id: this.student_year_tag_id };
      },
      skip() {
        return this.student_year_tag_id == null;
      },
      result({ data }) {
        if (!data) return;

        const {
          transcript_header_text,
          transcript_footer_text,
          fill_logo_color,
          transcript_show_student_year_tag,
          logo,
          transcriptFooterLogo,
        } = data.studentYearTag;

        if (
          [
            transcript_header_text,
            transcript_footer_text,
            fill_logo_color,
            transcript_show_student_year_tag,
            logo,
            transcriptFooterLogo,
          ].every((v) => v == null)
        ) {
          this.studentYearTag.use_client_transcript_settings = true;
        }
      },
    },
  },
  data() {
    return {
      newValue: {},
      studentYearTag: {},
      saving: false,
      fields: [
        {
          name: "name",
          validation: {
            required,
          },
          cols: 12,
        },
        {
          name: "years",
          type: "query",
          path: "me.client.years",
          query: gql`
            query Client {
              me {
                id
                client {
                  id
                  years {
                    id
                    name
                  }
                }
              }
            }
          `,
          bind: {
            clearable: true,
            multiple: true,
          },
          cols: 12,
        },
        {
          name: "has_academic_profile",
          type: "checkbox",
        },
        {
          name: "has_education_profile",
          type: "checkbox",
        },
        {
          name: "has_administration_profile",
          type: "checkbox",
        },
        {
          name: "has_transcript",
          type: "checkbox",
        },
        {
          name: "goal_time",
          type: "number",
          bind: {
            step: 0.1,
            min: 0,
            max: 999.9,
          },
          validation: {
            minValue: minValue(0),
            maxValue: maxValue(999.9),
          },
        },
        { name: "use_client_transcript_settings", type: "checkbox", cols: 12 },
        { name: "logo", type: "file", isVisible: (model) => !model.use_client_transcript_settings },
        { name: "transcriptFooterLogo", type: "file", isVisible: (model) => !model.use_client_transcript_settings },
        {
          name: "transcript_show_student_year_tag",
          type: "checkbox",
          isVisible: (model) => !model.use_client_transcript_settings,
        },
        { name: "fill_logo_color", type: "color", isVisible: (model) => !model.use_client_transcript_settings },
        {
          name: "transcript_header_text",
          type: "textarea",
          cols: 12,
          isVisible: (model) => !model.use_client_transcript_settings,
        },
        {
          name: "transcript_footer_text",
          type: "textarea",
          cols: 12,
          isVisible: (model) => !model.use_client_transcript_settings,
        },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      // eslint-disable-next-line no-unused-vars
      const { use_client_transcript_settings, ...input } = this.newValue;

      if (use_client_transcript_settings) {
        input.transcript_header_text = null;
        input.transcript_footer_text = null;
        input.fill_logo_color = null;
        input.transcript_show_student_year_tag = null;
        delete input.files;
        input.files = { create: [], delete: [] };

        if (this.studentYearTag.logo) {
          input.files.delete.push(this.studentYearTag.logo.id);
        }

        if (this.studentYearTag.transcriptFooterLogo) {
          input.files.delete.push(this.studentYearTag.transcriptFooterLogo.id);
        }
      }

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "StudentYearTag",
            fragment: STUDENT_YEAR_TAG_MUTATION_FRAGMENT,
            fragment_name: "studentYearTagMutation",
          }),
          variables: {
            id: this.student_year_tag_id,
            input,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      // eslint-disable-next-line no-unused-vars
      const { use_client_transcript_settings, ...input } = this.newValue;

      if (use_client_transcript_settings) {
        input.transcript_header_text = null;
        input.transcript_footer_text = null;
        input.fill_logo_color = null;
        input.transcript_show_student_year_tag = null;
        delete input.files;
      }

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "StudentYearTag",
            fragment: STUDENT_YEAR_TAG_MUTATION_FRAGMENT,
            fragment_name: "studentYearTagMutation",
          }),
          variables: {
            input,
          },
        })
        .then(({ data: { createStudentYearTag } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createStudentYearTag);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
