<template>
  <div>
    <v-card class="mb-4" rounded="lg">
      <v-card-text>
        <managed-residences-select v-model="selectedResidence" />
      </v-card-text>
    </v-card>
    <v-card class="mb-4" rounded="lg">
      <v-card-text>
        <client-years-select v-model="selectedYear" />
      </v-card-text>
    </v-card>
    <div v-if="selectedResidence && selectedYear">
      <v-skeleton-loader v-if="$apollo.queries.floors.loading" type="table" />
      <div v-else>
        <chambers-table
          @deletedFloor="deletedFloor"
          @createdChamber="(newElement) => addChamberToFloor(newElement.floor, newElement)"
          @deletedChamber="(deletedElement) => removeChamberFromFloor(floor, deletedElement)"
          @updatedChamber="(updatedElement) => updatedChamber(floor, updatedElement)"
          v-for="floor in floors"
          :key="floor.id"
          :floor="floor"
          class="mb-4"
        />
        <v-btn block @click="dialog = true" color="primary">{{ $t("dialog.add") }} {{ $tc("models.floor", 1) }}</v-btn>
        <v-dialog v-model="dialog" max-width="800px" persistent>
          <floor-form
            v-if="selectedResidence"
            @created="(newElement) => created(newElement)"
            :baseItem="{ residence: selectedResidence }"
            @cancel="dialog = false"
          />
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import ManagedResidencesSelect from "../inputs/ManagedResidencesSelect.vue";
import ClientYearsSelect from "../inputs/ClientYearsSelect.vue";
import FloorForm from "../forms/FloorForm.vue";
import ChambersTable, { CHAMBERS_TABLE_FRAGMENT } from "./components/ChambersTable.vue";
import { addToCacheArray, removeFromCacheArray } from "../../apollo/helpers";

const FLOORS_QUERY = gql`
  query Floors($id: ID!, $year_id: ID!) {
    residence(id: $id) {
      id
      floors {
        id
        ...chambersTable
      }
    }
  }
  ${CHAMBERS_TABLE_FRAGMENT}
`;

export default {
  components: { ManagedResidencesSelect, FloorForm, ChambersTable, ClientYearsSelect },
  name: "ChamberSection",
  data() {
    return {
      selectedResidence: null,
      selectedYear: null,
      floors: [],
      headers: [
        {
          text: this.$t("floor.name"),
          value: "name",
        },
      ],
      dialog: false,
    };
  },

  apollo: {
    floors: {
      query: FLOORS_QUERY,
      variables() {
        return {
          id: this.selectedResidence.id,
          year_id: this.selectedYear.id,
        };
      },
      update(data) {
        return data.residence.floors;
      },
      skip() {
        return !this.selectedResidence || !this.selectedYear;
      },
    },
  },

  methods: {
    addChamberToFloor(floor, newElement) {
      const floorIndex = this.floors.findIndex((f) => f.id === floor.id);

      if (floorIndex === -1) {
        this.$apollo.queries.floors.refetch();
        return;
      }

      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: FLOORS_QUERY,
          variables: {
            id: this.selectedResidence.id,
            year_id: this.selectedYear.id,
          },
        },
        queryField: `residence.floors[${floorIndex}].chambers`,
        newElement,
        orderBy: { name: "asc" },
      });
      this.dialog = false;
    },

    removeChamberFromFloor(floor, { id }) {
      const floorIndex = this.floors.findIndex((f) => f.id === floor.id);

      if (floorIndex === -1) {
        this.$apollo.queries.floors.refetch();
        return;
      }

      removeFromCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: FLOORS_QUERY,
          variables: {
            id: this.selectedResidence.id,
            year_id: this.selectedYear.id,
          },
        },
        queryField: `residence.floors[${floorIndex}].chambers`,
        id,
      });
    },

    updatedChamber(floor, updatedElement) {
      if (floor.id === updatedElement.floor.id) return;

      this.removeChamberFromFloor(floor, updatedElement);
      this.addChamberToFloor(updatedElement.floor, updatedElement);
    },

    createdFloor(newElement) {
      newElement.chambers = [];
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: FLOORS_QUERY,
          variables: {
            id: this.selectedResidence.id,
            year_id: this.selectedYear.id,
          },
        },
        queryField: "residence.floors",
        newElement,
      });
      this.dialog = false;
    },

    deletedFloor({ id }) {
      removeFromCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: FLOORS_QUERY,
          variables: {
            id: this.selectedResidence.id,
            year_id: this.selectedYear.id,
          },
        },
        queryField: "residence.floors",
        id,
      });
    },
  },
};
</script>
