<template>
  <base-form
    model="client"
    @save="client_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.client.loading"
    :currentValue="client"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";

const CLIENT_FORM_FRAGMENT = gql`
  fragment clientForm on Client {
    id
    transcript_header_text
    transcript_footer_text
    fill_logo_color
    transcript_show_student_year_tag
    candidatureSurvey {
      id
      name
    }
    logo: file(name: "logo") {
      id
      name
      link
    }
    transcriptFooterLogo: file(name: "transcriptFooterLogo") {
      id
      name
      link
    }
  }
`;

export default {
  components: { BaseForm },
  name: "ClientForm",
  props: {
    client_id: {},
    baseItem: { default: () => ({ fill_logo_color: "#00AEDB" }) },
    duplicating: Boolean,
  },

  watch: {
    client_id(val) {
      if (val == null) this.client = {};
    },
  },

  apollo: {
    client: {
      query: gql`
        query Client($id: ID!) {
          client(id: $id) {
            ...clientForm
          }
        }
        ${CLIENT_FORM_FRAGMENT}
      `,
      variables() {
        return { id: this.client_id };
      },
      skip() {
        return this.client_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      client: {},
      saving: false,
      fields: [
        { name: "logo", type: "file" },
        { name: "transcriptFooterLogo", type: "file" },
        { name: "transcript_show_student_year_tag", type: "checkbox" },
        { name: "fill_logo_color", type: "color" },
        {
          name: "candidatureSurvey",
          type: "query",
          path: "me.viewableSurveys",
          query: gql`
            query ViewableSurvey {
              me {
                id
                viewableSurveys(class: CANDIDATURE) {
                  id
                  name
                }
              }
            }
          `,
          label: this.$t("client.candidatureSurvey"),
          bind: { clearable: true },
        },
        { name: "transcript_header_text", type: "textarea", cols: 12 },
        { name: "transcript_footer_text", type: "textarea", cols: 12 },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Client",
            fragment: CLIENT_FORM_FRAGMENT,
            fragment_name: "clientForm",
          }),
          variables: {
            id: this.client_id,
            input: { ...this.newValue },
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Client",
            fragment: CLIENT_FORM_FRAGMENT,
            fragment_name: "clientForm",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createClient } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createClient);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
