<template>
  <div>
    <edit-card :title="$t('client.transcript_layout')" :info="info" v-if="!loading" :item="client" model="client">
      <template v-slot:form="{ close }">
        <client-form :client_id="client.id" @cancel="close" @save="close" />
      </template>
    </edit-card>
    <br />
    <edit-card :title="$t('client.privacyPolicy')" :info="privacyInfo" v-if="!loading" :item="client" model="client">
      <template v-slot:form="{ close }">
        <privacy-form :client_id="client.id" @cancel="close" @save="close" />
      </template>
    </edit-card>

    <v-skeleton-loader type="card" v-else rounded="lg" />
  </div>
</template>

<script>
import gql from "graphql-tag";
import EditCard from "../base/EditCard.vue";
import ClientForm from "../forms/ClientForm.vue";
import PrivacyForm from "../forms/PrivacyForm.vue";

const CLIENT_CARD_QUERY = gql`
  query ClientCard {
    me {
      id
      client {
        id
        canUpdate
        transcript_header_text
        transcript_footer_text
        fill_logo_color
        name
        candidatureSurvey {
          id
          name
        }
        logo: file(name: "logo") {
          id
          name
          link
        }
        transcriptFooterLogo: file(name: "transcriptFooterLogo") {
          id
          name
          link
        }
        privacyPolicy: file(name: "privacyPolicy") {
          id
          name
          link
        }
      }
    }
  }
`;

export default {
  components: { EditCard, ClientForm, PrivacyForm },
  name: "ClientSection",
  data() {
    return {
      info: [
        { field: "candidatureSurvey.name", label: this.$tc("client.candidatureSurvey") },
        { field: "logo", type: "file" },
        { field: "transcriptFooterLogo", type: "file" },
      ],
      privacyInfo: [{ field: "privacyPolicy", type: "file" }],
    };
  },
  methods: {},
  computed: {
    loading() {
      return this.$apollo.queries.client.loading;
    },
  },
  apollo: {
    client: {
      query: CLIENT_CARD_QUERY,
      update: (data) => data.me.client,
    },
  },
};
</script>
