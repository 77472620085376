<template>
  <div>
    <v-card class="overflow-hidden" rounded="lg">
      <v-card-title>
        <client-years-select @input="items = null" v-model="selectedYear" />
      </v-card-title>
    </v-card>
    <br />
    <div v-if="selectedYear">
      <v-card v-for="structure in structures" :key="structure.id" class="overflow-hidden mb-4" rounded="lg">
        <crud-table
          :items="structure.gradeReferences"
          model="grade_reference"
          :title="structure.name"
          :loading="loading"
          @deleteItem="deleteGradeReference"
        >
          <template v-slot:form="{ selected_id, close, duplicating }">
            <grade-reference-form
              :baseItem="{ structure, grade_reference: 30 }"
              :grade_reference_id="selected_id"
              :duplicating="duplicating"
              @cancel="close"
              @save="close"
              @created="(newElement) => gradeReferenceCreated(newElement, close)"
            />
          </template>
        </crud-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import CrudTable from "../base/CrudTable.vue";
import GradeReferenceForm, { GRADE_REFERENCE_FRAGMENT } from "../forms/GradeReferenceForm.vue";
import ClientYearsSelect from "../inputs/ClientYearsSelect.vue";
import { addToCacheFragmentArray, removeFromCacheFragmentArray } from "../../apollo/helpers";
import { deleteMutationBuilder } from "../../graphql/mutations";

const GRADE_REFERENCES = gql`
  query GradeReferences($year_id: ID!, $academic_year_id: ID!) {
    year(id: $year_id) {
      id
      structures {
        id
        name
        gradeReferences(academic_year_id: $academic_year_id) {
          id
          grade_reference
          class_year
          classArea {
            id
            name
          }
          academicYear {
            id
            name
          }
          structure {
            id
          }
        }
      }
    }
  }
`;

export default {
  components: { CrudTable, GradeReferenceForm, ClientYearsSelect },
  name: "GradeReferencesSection",
  data() {
    return {
      selectedYear: null,
    };
  },
  apollo: {
    structures: {
      query: GRADE_REFERENCES,
      variables() {
        return {
          year_id: this.selectedYear.id,
          academic_year_id: parseInt(this.selectedYear.academicYear.id),
        };
      },
      update: (data) => data.year.structures,
      skip() {
        return !this.selectedYear;
      },
    },
  },
  computed: {
    loading() {
      return this.$apollo.queries.structures.loading;
    },
  },
  methods: {
    gradeReferenceCreated(newElement, close) {
      addToCacheFragmentArray(this.$apollo.provider.defaultClient, {
        fragment_info: {
          fragment: gql`
            fragment structure on Structure {
              id
              gradeReferences(academic_year_id: ${this.selectedYear.academicYear.id}) {
                ...gradeReference
              }
            }
            ${GRADE_REFERENCE_FRAGMENT}
          `,
          fragmentName: "structure",
          id: `Structure:${newElement.structure.id}`,
        },
        array_path: "gradeReferences",
        newElement,
      });
      close();
    },

    deleteGradeReference({ id }) {
      this.$apollo.mutate({
        mutation: deleteMutationBuilder({
          model: "GradeReference",
          fragment: GRADE_REFERENCE_FRAGMENT,
          fragment_name: "gradeReference",
        }),
        variables: { id },
        update: (store, { data: { deleteGradeReference } }) =>
          removeFromCacheFragmentArray(store, {
            fragment_info: {
              fragment: gql`
                fragment structure on Structure {
                  id
                  gradeReferences(academic_year_id: ${this.selectedYear.academicYear.id}) {
                    ...gradeReference
                  }
                }
                ${GRADE_REFERENCE_FRAGMENT}
              `,
              fragmentName: "structure",
              id: `Structure:${deleteGradeReference.structure.id}`,
            },
            array_path: "gradeReferences",
            id,
          }),
      });
    },
  },
};
</script>

<style></style>
