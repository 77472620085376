<template>
  <base-form
    model="customLink"
    @save="custom_link_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.customLink.loading"
    :currentValue="customLink"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required, url } from "vuelidate/lib/validators";

const CUSTOM_LINK_FRAGMENT = gql`
  fragment customLinkForm on CustomLink {
    id
    name
    link

    client {
      id
    }
  }
`;

export default {
  components: { BaseForm },
  name: "CustomLinkForm",
  props: {
    custom_link_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    custom_link_id(val) {
      if (val == null) this.customLink = {};
    },
  },

  apollo: {
    customLink: {
      query: gql`
        query CustomLink($id: ID!) {
          customLink(id: $id) {
            ...customLinkForm
          }
        }
        ${CUSTOM_LINK_FRAGMENT}
      `,
      variables() {
        return { id: this.custom_link_id };
      },
      skip() {
        return this.custom_link_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      customLink: {},
      saving: false,
      fields: [
        {
          name: "name",
          validation: {
            required,
          },
        },
        {
          name: "link",
          validation: {
            required,
            url,
          },
        },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "CustomLink",
            fragment: CUSTOM_LINK_FRAGMENT,
            fragment_name: "customLinkForm",
          }),
          variables: {
            id: this.custom_link_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "CustomLink",
            fragment: CUSTOM_LINK_FRAGMENT,
            fragment_name: "customLinkForm",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createCustomLink } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createCustomLink);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
