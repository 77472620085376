<template>
  <div>
    <v-card class="mb-4" rounded="lg">
      <v-card-text>
        <managed-areas-select v-model="selectedArea" />
      </v-card-text>
    </v-card>

    <v-card v-if="selectedArea" class="overflow-hidden" rounded="lg">
      <crud-table v-if="!$apollo.queries.rooms.loading" :items="rooms" model="room" :title="$tc('models.room', 2)" @deleteItem="deleteRoom">
        <template v-slot:form="{ selected_id, close, duplicating }">
          <room-form
            :room_id="selected_id"
            :area_id="selectedArea.id"
            :duplicating="duplicating"
            @cancel="close"
            @save="close"
            @created="(newElement) => roomCreated(newElement, close)"
          />
        </template>
      </crud-table>
      <v-skeleton-loader v-else type="table" />
    </v-card>
  </div>
</template>

<script>
import gql from "graphql-tag";
import CrudTable from "../base/CrudTable.vue";
import ManagedAreasSelect from "../inputs/ManagedAreasSelect.vue";
import RoomForm from "../forms/RoomForm.vue";
import { addToCacheArray, deleteModel } from "../../apollo/helpers";

const ROOMS_QUERY = gql`
  query AreaRooms($id: ID!) {
    area(id: $id) {
      id
      rooms {
        id
        name
      }
    }
  }
`;

export default {
  components: { CrudTable, ManagedAreasSelect, RoomForm },
  name: "RoomsSection",
  data() {
    return {
      selectedArea: null,
      rooms: [],
    };
  },
  methods: {
    roomCreated(newElement, close) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: ROOMS_QUERY,
          variables: { id: this.selectedArea.id },
        },
        queryField: "area.rooms",
        newElement,
      });
      close();
    },

    deleteRoom({ id }) {
      deleteModel(this.$apollo, {
        id,
        model: "Room",
        query: { query: ROOMS_QUERY, variables: { id: this.selectedArea.id } },
        queryField: "area.rooms",
      });
    },
  },

  apollo: {
    rooms: {
      query: ROOMS_QUERY,
      variables() {
        return {
          id: this.selectedArea.id,
        };
      },
      update: (data) => data.area.rooms,
      skip() {
        return this.selectedArea == null;
      },
    },
  },
};
</script>