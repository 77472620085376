<template>
  <base-form
    model="client"
    @save="client_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.client.loading"
    :currentValue="client"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";

const CLIENT_PRIVACY_FORM_FRAGMENT = gql`
  fragment clientPrivacyForm on Client {
    id
    privacyPolicy: file(name: "privacyPolicy") {
      id
      name
      link
    }
  }
`;

export default {
  components: { BaseForm },
  name: "PrivacyForm",
  props: {
    client_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    client_id(val) {
      if (val == null) this.client = {};
    },
  },

  apollo: {
    client: {
      query: gql`
        query ClientPrivacy($id: ID!) {
          client(id: $id) {
            ...clientPrivacyForm
          }
        }
        ${CLIENT_PRIVACY_FORM_FRAGMENT}
      `,
      variables() {
        return { id: this.client_id };
      },
      skip() {
        return this.client_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      client: {},
      saving: false,
      fields: [{ name: "privacyPolicy", type: "file" }],
    };
  },
  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Client",
            fragment: CLIENT_PRIVACY_FORM_FRAGMENT,
            fragment_name: "clientPrivacyForm",
          }),
          variables: {
            id: this.client_id,
            input: { ...this.newValue },
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Client",
            fragment: CLIENT_PRIVACY_FORM_FRAGMENT,
            fragment_name: "clientPrivacyForm",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createClient } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createClient);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
