<template>
  <base-form
    model="tag"
    @save="tag_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.tag.loading"
    :currentValue="tag"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form" />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";

const TAG_FORM_FRAGMENT = gql`
  fragment tagForm on Tag {
    id
    name
    color
    max_hours
    years {
      id
      name
    }
  }
`;

export default {
  components: { BaseForm },
  name: "TagForm",
  props: {
    tag_id: {},
    baseItem: { default: () => ({ color: "#00AEDB" }) },
    duplicating: Boolean,
  },

  watch: {
    tag_id(val) {
      if (val == null) this.tag = {};
    },
  },

  apollo: {
    tag: {
      query: gql`
        query Tag($id: ID!) {
          tag(id: $id) {
            ...tagForm
          }
        }
        ${TAG_FORM_FRAGMENT}
      `,
      variables() {
        return { id: this.tag_id };
      },
      skip() {
        return this.tag_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      tag: {},
      saving: false,
      fields: [
        {
          name: "name",
          validation: {
            required,
          },
        },
        {
          name: "color",
          type: "color",
          validation: {
            required,
          },
        },
        {
          name: "max_hours",
          type: "number",
          bind:{
            clearable: true,
          }
        },
        {
          name: "years",
          type: "query",
          path: "me.client.years",
          query: gql`
            query Client {
              me {
                id
                client {
                  id
                  years {
                    id
                    name
                  }
                }
              }
            }
          `,
          bind: {
            clearable: true,
            multiple: true,
          },
        },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      let input = this.newValue;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({ model: "Tag", fragment: TAG_FORM_FRAGMENT, fragment_name: "tagForm" }),
          variables: {
            id: this.tag_id,
            input,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      let input = this.newValue;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({ model: "Tag", fragment: TAG_FORM_FRAGMENT, fragment_name: "tagForm" }),
          variables: {
            input,
          },
        })
        .then(({ data: { createTag } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createTag);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
