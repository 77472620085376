<template>
  <base-form
    model="room"
    @save="room_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.room.loading"
    :currentValue="room"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>


<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";

const ROOM_FRAGMENT = gql`
  fragment room on Room {
    id
    name
    residence {
      id
      name
    }
  }
`;

export default {
  components: { BaseForm },
  name: "RoomForm",
  props: {
    room_id: {},
    area_id: { required: true },
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    room_id(val) {
      if (val == null) this.room = {};
    },
  },

  apollo: {
    room: {
      query: gql`
        query Room($id: ID!) {
          room(id: $id) {
            ...room
          }
        }
        ${ROOM_FRAGMENT}
      `,
      variables() {
        return { id: this.room_id };
      },
      skip() {
        return this.room_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      room: {},
      saving: false,
      fields: [
        {
          name: "name",
          validation: {
            required,
          },
        },
        {
          name: "residence",
          type: "query",
          path: "area.residences",
          query: gql`
            query AreaResidences($id: ID!) {
              area(id: $id) {
                id
                residences {
                  id
                  name
                }
              }
            }
          `,
          variables: () => ({ id: this.area_id }),
          label: this.$t("residence.name"),
          validation: {
            required,
          },
        },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Room",
            fragment: ROOM_FRAGMENT,
            fragment_name: "room",
          }),
          variables: {
            id: this.room_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Room",
            fragment: ROOM_FRAGMENT,
            fragment_name: "room",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createRoom } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createRoom);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>