<template>
  <base-form
    model="floor"
    @save="floor_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.floor.loading"
    :currentValue="floor"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";

const FLOOR_FRAGMENT = gql`
  fragment floor on Floor {
    id
    name
    residence {
      id
      name
    }
  }
`;

export default {
  components: { BaseForm },
  name: "FloorForm",
  props: {
    floor_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    floor_id(val) {
      if (val == null) this.floor = {};
    },
  },

  apollo: {
    floor: {
      query: gql`
        query Floor($id: ID!) {
          floor(id: $id) {
            ...floor
          }
        }
        ${FLOOR_FRAGMENT}
      `,
      variables() {
        return { id: this.floor_id };
      },
      skip() {
        return this.floor_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      floor: {},
      saving: false,
      fields: [
        {
          name: "name",
          validation: {
            required,
          },
        },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "Floor",
            fragment: FLOOR_FRAGMENT,
            fragment_name: "floor",
          }),
          variables: {
            id: this.floor_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "Floor",
            fragment: FLOOR_FRAGMENT,
            fragment_name: "floor",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createFloor } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createFloor);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
