<template>
  <base-form
    model="gradeReference"
    @save="grade_reference_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.gradeReference.loading"
    :currentValue="gradeReference"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>


<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import _range from "lodash/range";

export const GRADE_REFERENCE_FRAGMENT = gql`
  fragment gradeReference on GradeReference {
    id
    grade_reference
    class_year
    classArea {
      id
      name
    }
    academicYear {
      id
      name
    }
    structure {
      id
      name
    }
  }
`;

export default {
  components: { BaseForm },
  name: "GradeReferenceForm",
  props: {
    grade_reference_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean
  },

  watch: {
    grade_reference_id(val) {
      if (val == null) this.gradeReference = {};
    },
  },

  apollo: {
    gradeReference: {
      query: gql`
        query GradeReference($id: ID!) {
          gradeReference(id: $id) {
            ...gradeReference
          }
        }
        ${GRADE_REFERENCE_FRAGMENT}
      `,
      variables() {
        return { id: this.grade_reference_id };
      },
      skip() {
        return this.grade_reference_id == null;
      },
    },
  },

  data() {
    return {
      newValue: {},
      gradeReference: {},
      saving: false,
      fields: [
        {
          name: "structure",
          type: "query",
          path: "structures",
          label: this.$tc("structure.name", 1),
          query: gql`
            query Structures {
              structures {
                id
                name
              }
            }
          `,
          validation: {
            required,
          },
          bind: {
            readonly: true,
          },
        },
        {
          name: "academicYear",
          label: this.$tc("academicYear.name", 1),
          type: "query",
          path: "academicYears",
          query: gql`
            query AcademicYears {
              academicYears {
                id
                name
              }
            }
          `,
          validation: {
            required,
          },
        },
        {
          name: "grade_reference",
          type: "number",
          bind: {
            step: 0.1,
            min: 18,
            max: 30,
          },
          validation: {
            required,
          },
        },
        {
          name: "class_year",
          type: "autocomplete",
          items: _range(1, 7).map((value) => ({
            text: value,
            value,
          })),
          no_translation: true,
          validation: {
            required,
          },
        },
        {
          name: "classArea",
          label: this.$tc("classArea.name", 1),
          type: "query",
          path: "classAreas",
          query: gql`
            query ClassAreas {
              classAreas {
                id
                name
              }
            }
          `,
          validation: {
            required,
          },
        },
      ],
    };
  },

  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({ model: "GradeReference", fragment: GRADE_REFERENCE_FRAGMENT, fragment_name: "gradeReference" }),
          variables: {
            id: this.grade_reference_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({ model: "GradeReference", fragment: GRADE_REFERENCE_FRAGMENT, fragment_name: "gradeReference" }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createGradeReference } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createGradeReference);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>