<template>
  <base-table
    :title="$tc('models.studentYearTag', 2)"
    :items="studentYearsTags"
    :headers="headers"
    :tableProps="tableProps"
    @deleteItem="deleteStudentYearTag"
  >
    <template v-slot:form="{ selected_id, close, duplicating }">
      <student-year-tag-form
        :student_year_tag_id="selected_id"
        :baseItem="baseItem"
        @cancel="close"
        :duplicating="duplicating"
        @save="(studentYearTag) => studentYearTagUpdated(studentYearTag, close)"
        @created="(studentYearTag) => studentYearTagCreated(studentYearTag, close)"
      />
    </template>
    <template v-slot:[`profiles`]="{ item }">
      <div class="d-flex flex-column align-start">
        <v-chip color="primary" small class="ma-1 flex" v-if="item.has_academic_profile">
          {{ $t("studentYearTag.has_academic_profile") }}
        </v-chip>
        <v-chip color="primary" small class="ma-1 flex" v-if="item.has_education_profile">
          {{ $t("studentYearTag.has_education_profile") }}
        </v-chip>
        <v-chip color="primary" small class="ma-1 flex" v-if="item.has_administration_profile">
          {{ $t("studentYearTag.has_administration_profile") }}
        </v-chip>
        <v-chip color="primary" small class="ma-1 flex" v-if="item.has_transcript">
          {{ $t("studentYearTag.has_transcript") }}
        </v-chip>
      </div>
    </template>
  </base-table>
</template>

<script>
export const STUDENT_YEAR_TAGS_TABLE_FRAGMENT = gql`
  fragment studentYearTagsTable on StudentYearTag {
    id
    name
    has_academic_profile
    has_education_profile
    has_administration_profile
    has_transcript
    goal_time
  }
`;

import BaseTable from "./BaseTable.vue";
import StudentYearTagForm from "../forms/StudentYearTagForm.vue";
import gql from "graphql-tag";
import moment from "moment";
import { deleteModelMutation } from "../../graphql/mutations";

export default {
  components: { BaseTable, StudentYearTagForm },
  name: "StudentYearTagsTable",
  props: {
    studentYearsTags: Array,
    baseItem: {
      type: Object,
      default: () => ({
        has_academic_profile: true,
        has_education_profile: true,
        has_administration_profile: true,
        has_transcript: true,
      }),
    },
    tableProps: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      headers: [
        {
          value: "name",
          text: this.$t("studentYearTag.name"),
        },
        {
          value: "profiles",
          text: this.$t("studentYearTag.enabled_profiles"),
          type: "template",
        },
        {
          value: "goal_time",
          text: this.$t("studentYearTag.goal_time"),
        },
      ],

      moment,
    };
  },

  methods: {
    studentYearTagUpdated(studentYearTag, close) {
      this.$emit("studentYearTagUpdated", studentYearTag);
      close();
    },

    studentYearTagCreated(studentYearTag, close) {
      this.$emit("studentYearTagCreated", studentYearTag);
      close();
    },

    deleteStudentYearTag(studentYearTag) {
      this.$apollo
        .mutate({
          mutation: deleteModelMutation("StudentYearTag"),
          variables: { id: studentYearTag.id },
        })
        .then(() => {
          this.$emit("studentYearTagDeleted", studentYearTag);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
