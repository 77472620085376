<template>
  <base-table :title="$tc('models.tuitionRange', 2)" :items="tuitionRanges" :headers="headers" v-bind="tableProps" @deleteItem="deleteTuitionRange">
    <template v-slot:[`creator`]="{ item }">
      <router-link :to="{ name: 'UserProfile', params: { id: item.creator.id } }">
        {{ item.creator.complete_name }}
      </router-link>
    </template>
    <template v-slot:form="{ selected_id, close, duplicating }">
      <tuitionRange-form
        :tuition_range_id="selected_id"
        :baseItem="baseItem"
        @cancel="close"
        :duplicating="duplicating"
        @save="(tuitionRange) => tuitionRangeUpdated(tuitionRange, close)"
        @created="(tuitionRange) => tuitionRangeCreated(tuitionRange, close)"
      />
    </template>
  </base-table>
</template>

<script>
export const TUITION_RANGES_TABLE_FRAGMENT = gql`
  fragment tuitionRangesTable on TuitionRange {
    id
    name
    isee_limit
    isee_reduction
  }
`;

import BaseTable from "./BaseTable.vue";
import TuitionRangeForm from "../forms/TuitionRangeForm.vue";
import gql from "graphql-tag";
import moment from "moment";
import { deleteModelMutation } from "../../graphql/mutations";

export default {
  components: { BaseTable, TuitionRangeForm },
  name: "TuitionRangesTable",
  props: {
    tuitionRanges: { type: Array, required: true },
    baseItem: { type: Object, default: () => ({}) },
    tableProps: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      headers: [
        { value: "name", text: this.$t("tuitionRange.name") },
        { value: "isee_limit", text: this.$t("tuitionRange.isee_limit"), type: "currency" },
        { value: "isee_reduction", text: this.$t("tuitionRange.isee_reduction"), type: "currency" },
      ],

      moment,
    };
  },

  methods: {
    tuitionRangeUpdated(tuitionRange, close) {
      this.$emit("tuitionRangeUpdated", tuitionRange);
      close();
    },

    tuitionRangeCreated(tuitionRange, close) {
      this.$emit("tuitionRangeCreated", tuitionRange);
      close();
    },

    deleteTuitionRange(tuitionRange) {
      this.$apollo
        .mutate({
          mutation: deleteModelMutation("TuitionRange"),
          variables: { id: tuitionRange.id },
        })
        .then(() => {
          this.$emit("tuitionRangeDeleted", tuitionRange);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>