<template>
  <base-form
    model="residenceYear"
    @save="residence_year_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.residenceYear.loading"
    :currentValue="residenceYear"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>


<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";

export const RESIDENCE_YEAR_FORM_FRAGMENT = gql`
  fragment residenceYearForm on ResidenceYear {
    id
    max_tuition
    education_bonus
  }
`;

export default {
  components: { BaseForm },
  name: "ResidenceYearForm",
  props: {
    residence_year_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    residence_year_id(val) {
      if (val == null) this.residenceYear = {};
    },
  },

  apollo: {
    residenceYear: {
      query: gql`
        query ResidenceYear($id: ID!) {
          residenceYear(id: $id) {
            ...residenceYearForm
          }
        }
        ${RESIDENCE_YEAR_FORM_FRAGMENT}
      `,
      variables() {
        return { id: this.residence_year_id };
      },
      skip() {
        return this.residence_year_id == null;
      },
    },
  },

  data() {
    return {
      newValue: {},
      residenceYear: {},
      saving: false,
      fields: [
        {
          name: "max_tuition",
          type: "number",
          validation: {
            required,
          },
          bind: {
            step: 0.01,
            "append-icon": "mdi-currency-eur",
          },
        },
        {
          name: "education_bonus",
          type: "number",
          validation: {
            required,
          },
          bind: {
            step: 0.01,
            "append-icon": "mdi-currency-eur",
          },
        },
      ],
    };
  },

  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "ResidenceYear",
            fragment: RESIDENCE_YEAR_FORM_FRAGMENT,
            fragment_name: "residenceYearForm",
          }),
          variables: {
            id: this.residence_year_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "ResidenceYear",
            fragment: RESIDENCE_YEAR_FORM_FRAGMENT,
            fragment_name: "residenceYearForm",
          }),
          variables: {
            input: this.newValue,
          },
        })
        .then(({ data: { createResidenceYear } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createResidenceYear);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>